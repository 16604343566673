.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.chart-container {
  background-color: #46728d7d; /* Nastaví světle šedé pozadí */

  padding: 20px; /* Přidá vnitřní odsazení kolem grafu */
  border-radius: 8px; /* Přidá zaoblení rohů */
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}

.logo {
  height: 40px;
}

.user-info {
  margin: 0;
  font-size: 16px;
}

.App-header {
  background-color: blue;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.dark-background {
  /* background-color: #121212; */
  background-color: #004c7c;
  background-color: linear-gradient(135deg, #004c7c 0%, #0077b6 100%);
  color: #ffffff;
  min-height: 100vh;
  padding: 20px;
}

.nav-buttons {
  display: inline-block;
  /* margin-bottom: 10px; */
  /* margin-top: 50px; */
}

h1 {
  display: block;
  font-size: 2em;
  text-align: center;
  margin-bottom: 200px;
  height: 75px;
}

.filter-area {
  /* background-color: gray; */
  justify-content: center;
  margin-bottom: 20px;
}

.table-success {
  font-weight: bold;
}

.btn {
  margin-right: 2px;
  width: 100px;
}

.data-container {
  position: relative; /* Container for positioning the loader */
}

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3); /* Semi-transparent overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Keep it above the content */
}

.content {
  opacity: 1; /* Keep the content fully visible */
}

.tooltip {
  font-size: 2.2rem; /* Zvětší text */
  padding: 10px; /* Přidá více místa kolem textu */
  max-width: 300px; /* Přizpůsobí šířku */
}

.tooltip-inner {
  background-color: #f8f9fa; /* Světlejší pozadí */
  color: #000; /* Tmavší text */
  font-size: 2.2rem; /* Zvětší velikost textu */
  padding: 10px; /* Zvětší mezery uvnitř */
  border-radius: 8px; /* Kulaté rohy */
  text-align: left; /* Zarovná text doleva */
}
