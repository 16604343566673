/* Nastavení základního pozadí */
.login-screen {
  background: linear-gradient(135deg, #86b6d4 0%, #0077b6 100%);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}

.login-container {
  text-align: center;
  width: 300px;
  padding: 20px;
}

.logo {
  width: 150px;
  margin-bottom: 20px;
}

.welcome-text {
  font-family: "Montserrat", sans-serif;
  font-size: 1.2em;
  margin-bottom: 20px;
}

.sales-image img {
  width: 100%;
  border-radius: 10px;
  margin-bottom: 20px;
}

.login-button {
  background-color: #ffffff;
  color: #004c7c;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s;
}

.login-button:hover {
  background-color: #e0e0e0;
}
